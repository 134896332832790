<template>
  <div class="container">
    <section class="productCenter-background"><img src="../assets/img/productCenter/productCenter.png"
        alt="LinkingInnovations" class="productCenter-background-logo">
    </section>
    <section class="section-product-text">{{ $t('ProductCenter.section-product') }}</section>
    <section class="section-product"
      :class="{ 'section-product-reverse': index === 1, 'section-product-line': index === 2 }"
      v-for="(item, index) in productList" :key="index">
      <div class="section-product-content">
        <div class="section-product-content-title" :class="{ 'section-product-content-title-three': index === 2 }">
          {{ item.title }}
        </div>
        <!-- <div class="section-product-content-title-information">{{ item.tooltip }}</div> -->
        <div class="section-product-content-line" />
        <div class="section-product-content-information" v-for="(list, ind) in item.text" :key="ind">
          <span class="section-product-content-information-title">{{ list.title }}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span
            class="section-product-content-information-content">{{ list.content }}</span>
        </div>
      </div>
      <img :src="item.url" alt="LinkingInnovations"
        :class="{ 'section-product-logo-three': index === 2, 'section-product-logo-two': index === 1, 'section-product-logo-one': index === 0 }" />
    </section>
  </div>
</template>

<script>
export default {
  name: "ProductCenter",
  data() {
    return {
      productList: [
        {
          title: this.$t('ProductCenter.productList.productListOne.title'),
          text: [{ title: this.$t('ProductCenter.productList.productListOne.textOneTitle'), content: this.$t('ProductCenter.productList.productListOne.textOne') },
          { title: this.$t('ProductCenter.productList.productListOne.textTwoTitle'), content: this.$t('ProductCenter.productList.productListOne.textTwo') },
          { title: this.$t('ProductCenter.productList.productListOne.textThreeTitle'), content: this.$t('ProductCenter.productList.productListOne.textThree') },
          { title: this.$t('ProductCenter.productList.productListOne.textFourTitle'), content: this.$t('ProductCenter.productList.productListOne.textFour') },
          { title: this.$t('ProductCenter.productList.productListOne.textFiveTitle'), content: this.$t('ProductCenter.productList.productListOne.textFive') }],
          url: require("../assets/img/productCenter/ProductOne.png")
        },
        {
          title: this.$t('ProductCenter.productList.productListThree.title'),
          text: [{ title: this.$t('ProductCenter.productList.productListThree.textOneTitle'), content: this.$t('ProductCenter.productList.productListThree.textOne') },
          { title: this.$t('ProductCenter.productList.productListThree.textTwoTitle'), content: this.$t('ProductCenter.productList.productListThree.textTwo') },
          { title: this.$t('ProductCenter.productList.productListThree.textThreeTitle'), content: this.$t('ProductCenter.productList.productListThree.textThree') },
          { title: this.$t('ProductCenter.productList.productListThree.textFourTitle'), content: this.$t('ProductCenter.productList.productListThree.textFour') },
          { title: this.$t('ProductCenter.productList.productListThree.textFiveTitle'), content: this.$t('ProductCenter.productList.productListThree.textFive') }],
          url: require("../assets/img/productCenter/ProductTwo.png")
        },
        {
          title: this.$t('ProductCenter.productList.productListTwo.title'),
          text: [{ title: this.$t('ProductCenter.productList.productListTwo.textOneTitle'), content: this.$t('ProductCenter.productList.productListTwo.textOne') },
          { title: this.$t('ProductCenter.productList.productListTwo.textTwoTitle'), content: this.$t('ProductCenter.productList.productListTwo.textTwo') },
          { title: this.$t('ProductCenter.productList.productListTwo.textThreeTitle'), content: this.$t('ProductCenter.productList.productListTwo.textThree') },
          { title: this.$t('ProductCenter.productList.productListTwo.textFourTitle'), content: this.$t('ProductCenter.productList.productListTwo.textFour') }],
          url: require("../assets/img/productCenter/ProductThree.jpg")
        }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.container {
  width: 100%;
  box-sizing: border-box;
}

.productCenter-background {
  width: 100%;
  height: 600px;
}

.productCenter-background-logo {
  display: block;
  width: 100%;
  height: 100%;
}

.section-product {
  display: flex;
  justify-content: space-between;
  margin: 0 240px 0;
  padding: 0;
  border-bottom: 1px solid #979797;
}

.section-product-reverse {
  flex-direction: row-reverse;
}

.section-product-line {
  margin-bottom: 0;
  border-bottom: 0;
}

.section-product-content {
  width: 100%;
  margin: 100px 0;
}

.section-product-logo-one {
  width: auto;
  height: 300px;
  margin: auto 0 auto 10px;
}

.section-product-logo-two {
  width: auto;
  height: 300px;
  margin: auto 118px auto 0;
}

.section-product-logo-three {
  width: auto;
  height: 300px;
  margin: auto 0 auto 118px;
}

.section-product-content-title {
  text-align: left;
  font-size: 26px;
  font-weight: 600;
  color: #FFFFFF;
  margin: 20px 0 10px 0;
  line-height: 1;
}

.section-product-content-title-three {
  margin-top: 34px;
}

.section-product-content-line {
  width: 150px;
  height: 3px;
  margin: 16px 0 20px 0;
  background: #22DCA1;
}

.section-product-content-information {
  display: flex;
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  color: #FFFFFF;
  margin-bottom: 10px;
  line-height: 30px;
}

.section-product-content-information-title {
  font-size: 20px;
}

.section-product-content-information-content {
  flex: 1;
  color: rgba(255, 255, 255, 0.7);
}

.section-product-content-title-information {
  text-align: left;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 28px;
}

.section-product-text {
  position: relative;
  height: 78px;
  line-height: 78px;
  font-size: 40px;
  font-weight: 600;
  color: #FFFFFF;
  margin-top: 100px;
  margin-bottom: 74px;
  width: 100%;
  padding: 0 200px;
}

@media screen and (max-width: 768px) {
  .productCenter-background {
    width: 100%;
    height: 140px;
  }

  .productCenter-background-logo {
    display: block;
    width: 100%;
    height: 100%;
  }

  .section-product {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin: 0 0 20px;
    padding: 0 12px 20px;
    overflow: hidden;
    border-bottom: 1px solid #979797;
  }

  .section-product-reverse {
    flex-direction: column-reverse;
  }

  .section-product-line {
    margin-bottom: 0;
    border-bottom: 0;
  }

  .section-product-content {
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0 0;
  }

  .section-product-logo {
    width: 210px;
    height: 116px;
    margin: 0 auto;
  }

  .section-product-content-title {
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    margin: 8px 0 4px 0;
    line-height: 16px;
    overflow: hidden;
  }

  .section-product-content-title-three {
    margin-top: 13px;
  }

  .section-product-content-line {
    width: 60px;
    height: 1px;
    margin: 8px auto 6px;
    background: #22DCA1;
  }

  .section-product-content-information {
    text-align: left;
    font-size: 8px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 14px;
  }

  .section-product-content-information-title {
    font-size: 10px;

  }

  .section-product-content-information-content {
    color: rgba(255, 255, 255, 0.7);
  }

  .section-product-content-title-information {
    text-align: center;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 11px;
  }

  .section-product-text {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 600;
    color: #FFFFFF;
    margin: 10px 0;
    width: 100%;
    padding: 0 12px;
  }

  .section-product-logo-one {
    margin: 0 auto;
    width: 50%;
    height: 100px;
    margin: auto;
  }

  .section-product-logo-two {
    width: 100%;
    height: 100px;
    margin: auto;
  }

  .section-product-logo-three {
    width: 100%;
    height: 100px;
    margin: auto;
  }
}
</style>